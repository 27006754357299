<form [formGroup]="filterForm">
  <div class="filter">
    <mul-keyword-balloon
      (dataMutationEventEmitter)="setKeywords($event)"
      [selection]="keywordSelection"
      class="keyword"
      componentId="target_keywords"
      label="Target Keywords"
      placeholder="Type in a keyword and press ⮐"
    ></mul-keyword-balloon>
  </div>
  <mul-multi-select
    class="filter"
    label="Target Themes"
    tooltip="Lorem ipsum dolor sit amet, consectetur."
    componentId="target_theme"
    placeholder="E.g. Consumer SaaS or SportsTech"
    [dataSet]="targetThemesDataSet"
    [selection]="targetThemeSelection"
    (dataMutationEventEmitter)="setTheme($event)"
  ></mul-multi-select>
  <mul-multi-select
    class="filter"
    label="Target Verticals"
    tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec dui nunc."
    componentId="target_verticals"
    placeholder="E.g. Music or Online Payments"
    [dataSet]="verticalDataSet"
    [selection]="verticalSelection"
    (dataMutationEventEmitter)="setVerticals($event)"
  ></mul-multi-select>
  <div class="filter">
    <mul-multi-select
      label="Target Names"
      tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod."
      componentId="target_name"
      placeholder="Start typing a name"
      [dataSet]="targetOrganizationDataSet"
      [selection]="targetOrganizationSelection"
      (dataMutationEventEmitter)="setOrganizations($event)"
    ></mul-multi-select>
  </div>
  <mul-multi-select
    class="filter"
    label="Target Geography"
    tooltip="Lorem ipsum dolor sit amet, consectetur."
    componentId="target_geography"
    placeholder="E.g. Europe, Brazil or India"
    [dataSet]="geographyDataSet"
    [selection]="geographySelection"
    (dataMutationEventEmitter)="setGeography($event)"
  ></mul-multi-select>
  <div class="filter form">
    <h2>Target Financials</h2>

    <div class="flex flex-col mt-3 mb-6">
      <h3>Revenue ($M)</h3>
      <div class="flex">
        <div class="flex flex-col">
          <div class="relative float-label">
            <input
              (keyup.enter)="onFormItemChange()"
              id="revenueMin"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="revenueMin"
              placeholder=" "
              type="text"
            />
            <label
              for="revenueMin"
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >min (⮐ to apply)</label
            >
          </div>
        </div>
        <div class="flex flex-col ml-2">
          <div class="relative float-label">
            <input
              id="revenueMax"
              (keyup.enter)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="revenueMax"
              placeholder=" "
              type="text"
            />
            <label
              for="revenueMax"
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >max (⮐ to apply)</label
            >
          </div>
        </div>
      </div>
      <mul-switch
        label="Exclude companies with no revenue"
        [value]="filterSelection.revenue.excludeNonReportedItems || false"
        (dataMutationEventEmitter)="setRevenueExcludeNonReportedItems($event)"
      ></mul-switch>
    </div>
    <div class="flex flex-col mt-3 mb-6">
      <h3>EBITDA ($M)</h3>
      <div class="flex">
        <div class="flex flex-col">
          <div class="relative float-label">
            <input
              (keyup.enter)="onFormItemChange()"
              id="ebitdaMin"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="ebitdaMin"
              placeholder=" "
              type="text"
            />
            <label
              for="ebitdaMin"
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >min (⮐ to apply)</label
            >
          </div>
        </div>
        <div class="flex flex-col ml-2">
          <div class="relative float-label">
            <input
              id="ebitdaMax"
              (keyup.enter)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="ebitdaMax"
              placeholder=" "
              type="text"
            />
            <label
              for="ebitdaMax"
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >max (⮐ to apply)</label
            >
          </div>
        </div>
      </div>
      <mul-switch
        label="Exclude companies with no EBITDA"
        [value]="filterSelection.ebitda.excludeNonReportedItems || false"
        (dataMutationEventEmitter)="setEbitdaExcludeNonReportedItems($event)"
      ></mul-switch>
    </div>
  </div>
</form>
