import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiStore {
  private _dataLoadingState: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  readonly dataLoadingState$ = this._dataLoadingState.asObservable();

  get dataLoadingState(): boolean {
    return this._dataLoadingState.getValue();
  }

  set dataLoadingState(value: boolean) {
    this._dataLoadingState.next(value);
  }
}
