import { Component, OnDestroy } from '@angular/core';
import { DataPaginationService } from '../../../service/data-pagination.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mul-data-paginator',
  templateUrl: './data-paginator.component.html'
})
export class DataPaginatorComponent implements OnDestroy {
  private dataPaginationStoreSubscription: Subscription = new Subscription();

  totalPages = 0;
  currentPage = 0;
  paginationLength = 10;
  pageRange: number[] = [];

  constructor(private readonly dataPaginationService: DataPaginationService) {
    this.dataPaginationStoreSubscription = this.dataPaginationService.dataPaginationStore.length$.subscribe(
      val => {
        this.totalPages = this.dataPaginationService.calculateTotalPages(
          val,
          this.dataPaginationService.dataPaginationStore.pageSize
        );

        this.currentPage = this.dataPaginationService.dataPaginationStore.page;

        this.dataPaginationService.dataPaginationStore.totalPages = this.totalPages;

        this.renderPagination();
      }
    );
  }

  ngOnDestroy(): void {
    this.dataPaginationStoreSubscription.unsubscribe();
  }

  renderPagination(): void {
    this.pageRange = this.dataPaginationService.pageRange(
      this.currentPage,
      this.paginationLength,
      this.totalPages
    );
  }

  setPage(page: number) {
    this.currentPage = page;

    this.renderPagination();

    this.dataPaginationService.dataPaginationStore.page = this.currentPage;
  }
}
