<section class="text-xs p-5">
  <div
    *ngIf="authorizationService.isEmbeddedAuth()"
    class="bg-logo_black dark:bg-logo_white bg-no-repeat w-[130px] h-[30px]"
  ></div>
  <mul-public-filter-clear></mul-public-filter-clear>
  <mul-multi-select
    class="filter"
    label="Companies"
    componentId="organizationRows"
    placeholder="Individual comps, e.g. Nvidia"
    [dataSet]="organizationDataSet"
    [selection]="organizationRowsSelection"
    (dataMutationEventEmitter)="setOrganizationRows($event)"
  ></mul-multi-select>
  <mul-multi-select
    class="filter"
    label="Verticals"
    componentId="verticals"
    placeholder="e.g. Software, Fintech or IoT"
    [dataSet]="verticalDataSet"
    [selection]="verticalSelection"
    (dataMutationEventEmitter)="setVerticals($event)"
  ></mul-multi-select>
  <mul-multi-select
    class="filter"
    label="Companies similar to"
    componentId="organizations"
    placeholder="e.g. Apple, MSFT"
    [dataSet]="organizationDataSet"
    [selection]="organizationSelection"
    (dataMutationEventEmitter)="setOrganizations($event)"
  ></mul-multi-select>
  <mul-multi-select
    class="filter"
    label="Geography"
    componentId="geograhy"
    placeholder="e.g. Europe, Brazil or India"
    [dataSet]="geographyDataSet"
    [selection]="geographySelection"
    (dataMutationEventEmitter)="setGeography($event)"
  ></mul-multi-select>
  <mul-financial-filter class="filter form"></mul-financial-filter>
  <mul-footer
    class="flex mt-3"
    *ngIf="authorizationService.isEmbeddedAuth()"
  ></mul-footer>
</section>
