import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResolveFn } from '@angular/router';
import { UIOptionType } from '../../data/type/filter/UIOption.type';
import { TargetOrganizationService } from '../target-organization.service';

@Injectable({ providedIn: 'root' })
export class TargetOrganizationFilterResolverService {
  constructor(private targetOrganizationService: TargetOrganizationService) {}

  resolve() {
    return this.targetOrganizationService.getAll$();
  }
}

export const targetOrganizationFilterResolver: ResolveFn<Observable<
  UIOptionType[]
>> = () => inject(TargetOrganizationFilterResolverService).resolve();
