import { Injectable } from '@angular/core';
import { PaginationMapperType } from '../../../data/type/grid/pagination-mapper.type';

@Injectable({
  providedIn: 'root'
})
export class PaginationMapperService {
  constructor() {}

  getRemotePage(
    localPage: number,
    pageSize: number,
    datasetSize: number
  ): PaginationMapperType {
    const pagesPerDataset = Math.ceil(datasetSize / pageSize);
    let remotePage = 1;

    while (localPage > pagesPerDataset * remotePage) {
      ++remotePage;
    }

    const relativeLocalPage =
      localPage > pagesPerDataset
        ? localPage - pagesPerDataset * (remotePage - 1)
        : localPage;

    return {
      remotePage,
      relativeLocalPage:
        relativeLocalPage === 0 ? pagesPerDataset : relativeLocalPage
    };
  }
}
