import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UIOptionType } from '../../../data-sheet/data/type/filter/UIOption.type';

@Component({
  selector: 'mul-balloon-selection',
  templateUrl: './balloon-set.component.html'
})
export class BalloonSetComponent {
  @Output() dataMutationEventEmitter: EventEmitter<
    string | number
  > = new EventEmitter();

  @Input() displayCount: number = 5;
  @Input() selection: UIOptionType[] = [];

  displayAllSelected = false;

  removeItem(index: string | number) {
    this.dataMutationEventEmitter.emit(index);
  }
}
