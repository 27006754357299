import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataPaginationStore {
  get relativePage(): number {
    return this._relativePage;
  }

  set relativePage(value: number) {
    this._relativePage = value;
  }
  get remotePage(): number {
    return this._remotePage;
  }

  set remotePage(value: number) {
    this._remotePage = value;
  }
  get dataSetSize(): number {
    return this._dataSetSize;
  }

  private _length: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private _pageSize: BehaviorSubject<number> = new BehaviorSubject<number>(100);

  private _paginationLength: BehaviorSubject<number> = new BehaviorSubject<
    number
  >(10);

  private _dataSetSize = 2000;

  private _remotePage = 1;
  private _relativePage = 1;

  private _page: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  private _totalPages = 0;

  private _offSet = 0;

  private _limit = this._pageSize.getValue() - 1;

  readonly length$ = this._length.asObservable();

  readonly page$ = this._page.asObservable();

  get length() {
    return this._length.getValue();
  }

  set length(val: number) {
    this._length.next(val);
  }

  get pageSize() {
    return this._pageSize.getValue();
  }

  set pageSize(val: number) {
    this._pageSize.next(val);
  }

  get paginationLength() {
    return this._paginationLength.getValue();
  }

  set paginationLength(val: number) {
    this._paginationLength.next(val);
  }

  get page() {
    return this._page.getValue();
  }

  set page(val: number) {
    this._page.next(val);
  }

  set totalPages(val: number) {
    this._totalPages = val;
  }

  get offSet() {
    return this._offSet;
  }

  set offSet(val: number) {
    this._offSet = val;
  }

  get limit() {
    return this._limit;
  }

  set limit(val: number) {
    this._limit = val;
  }
}
