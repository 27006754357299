import { Injectable } from '@angular/core';
import { ApiService } from '../../common/service/api.service';
import { Observable, tap } from 'rxjs';
import { UIOptionType } from '../data/type/filter/UIOption.type';
import { ApiRoutes } from '../../common/data/enum/routes.enum';
import { DealDataGridFilterService } from './deal-data-grid-filter.service';

@Injectable({
  providedIn: 'root'
})
export class BuyerOrganizationService {
  constructor(
    private api: ApiService,
    private dealDataGridFilterService: DealDataGridFilterService
  ) {}

  getAll$(): Observable<UIOptionType[]> {
    return this.api
      .httpGet$<UIOptionType[]>(ApiRoutes.BUYER_ORGANIZATIONS)
      .pipe(
        tap(
          val =>
            (this.dealDataGridFilterService.filterStorageService.buyerOrganizations = val)
        )
      );
  }
}
