import { Component } from '@angular/core';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { DarkModeService } from 'angular-dark-mode';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { UiStore } from '../../service/ui.store';

@Component({
  selector: 'mul-spinner',
  standalone: true,
  imports: [NgxSpinnerModule, AsyncPipe],
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent {
  isLoading = true;

  darkMode$: Observable<boolean> = this.darkModeService.darkMode$;

  constructor(
    private darkModeService: DarkModeService,
    private spinner: NgxSpinnerService,
    private uiStore: UiStore
  ) {
    this.uiStore.dataLoadingState$.subscribe(val => {
      this.isLoading = val;
      if (val) this.spinner.show('data');
      else this.spinner.hide('data');
    });
  }
}
