<div
  (click)="clearFilters()"
  class="mb-6 text-primary-500 dark:text-inverse-500 cursor-pointer h-2 {{
    filterCount === 0 ? 'hidden' : ''
  }}"
>
  <div class="flex flex-row justify-start items-center">
    <i class="fa-solid fa-filter-circle-xmark"></i>
    <span class="ml-1"> Clear all filters ({{ filterCount }}) </span>
  </div>
</div>
