import { Injectable, OnDestroy } from '@angular/core';
import { DataSheetStore } from './store/data-sheet.store';
import { FilterStorage } from './store/filter.storage';
import { DealDataFilterStore } from './store/deal-data-filter.store';
import { GridData } from '../data/type/organization/grid-data.type';
import {
  DealDataFilter,
  DealDataFilterFactory
} from '../data/type/filter/deal/deal-data-filter.type';
import { Subscription } from 'rxjs';
import { FilterRefEnum } from '../data/enum/grid/filter-ref.enum';
import { DataPaginationStore } from './store/data-pagination.store';
import { DataGridFilterToolsService } from './data-grid-filter-tools.service';
import { TargetFilterType } from '../data/type/filter/deal/target-filter.type';
import { BuyerFilterType } from '../data/type/filter/deal/buyer-filter-type';
import { DealInfoFilterType } from '../data/type/filter/deal/deal-info-filter.type';
import { FilterTriggerEvent } from './FilterTriggerEvent';
import { NavigationStore } from '../../navigation/service/store/navigation.store';
import { DataSortingService } from './data-sorting.service';
import { StoredPublicGridFilterFactory } from '../data/interface/stored-grid/stored-grid.interface';
import { MutationFactory } from '../data/type/mutator/data-mutator.type';
import { GridMutatorStore } from './mutator/grid-mutator.store';
import { StoredGridStore } from './store/stored-grid.store';
import { DealRemoteFilterStore } from './store/deal-remote-filter.store';

@Injectable({
  providedIn: 'root'
})
export class DealDataGridFilterService implements OnDestroy {
  private gridFilterSubscription: Subscription = new Subscription();
  private dataSheetStorageServiceSubscription: Subscription = new Subscription();
  private filterTriggerEventSubscription: Subscription = new Subscription();

  constructor(
    public dealDataFilterStore: DealDataFilterStore,
    public dataSheetStorageService: DataSheetStore,
    public filterStorageService: FilterStorage,
    private dataPaginationStoreService: DataPaginationStore,
    private dataGridFilterToolsService: DataGridFilterToolsService,
    private readonly dataSortingService: DataSortingService,
    private readonly mutatorStore: GridMutatorStore,
    private savedStoredGridStore: StoredGridStore,
    private filterTriggerEvent: FilterTriggerEvent,
    private readonly navigationStore: NavigationStore,
    private dealRemoteFilterStore: DealRemoteFilterStore
  ) {
    this.dealDataFilterStore.gridFilter$.subscribe(() => {
      this.dealDataFilterStore.filterCount = this._getFilterCount();
    });

    // this.gridFilterSubscription = this.dealDataFilterStore.gridFilter$.subscribe(
    //   async val => {
    //     const data = this.dataSheetStorageService.gridData;
    //
    //     if (data.length > 0) {
    //       console.debug('DEAL FILTERING: gridFilter$', val);
    //       await this.__processFilter(data, val);
    //     }
    //   }
    // );
    //
    // this.dataSheetStorageServiceSubscription = this.dataSheetStorageService.gridData$.subscribe(
    //   async val => {
    //     if (val.length > 0) {
    //       const filter = this.dealDataFilterStore.gridFilter;
    //       console.debug('FILTERING: gridData$', val.length, filter);
    //
    //       await this.__processFilter(val, filter);
    //     }
    //   }
    // );
    //
    // this.filterTriggerEventSubscription = this.filterTriggerEvent.triggerDealFilter$.subscribe(
    //   async () => {
    //     const data = this.dataSheetStorageService.gridData;
    //     const filter = this.dealDataFilterStore.gridFilter;
    //     console.debug('DEAL FILTERING DEAL: triggerFilter$', data.length);
    //
    //     await this.__processFilter(data, filter);
    //   }
    // );
  }

  ngOnDestroy(): void {
    this.gridFilterSubscription.unsubscribe();
    this.dataSheetStorageServiceSubscription.unsubscribe();
    this.filterTriggerEventSubscription.unsubscribe();
  }

  private async __processFilter(data: GridData[], filter: DealDataFilter) {
    data = this._processTargetFilter(data, filter.targetFilter);
    data = this._processBuyerFilter(data, filter.buyerFilter);
    data = this._processDealInfoFilter(data, filter.dealInfoFilter);

    this.dataSheetStorageService.gridDisplayData = data;
    this.dataPaginationStoreService.page = 1;
    this.dataPaginationStoreService.length = data.length;

    this.dealDataFilterStore.filterCount = this._getFilterCount();
    this.dataSortingService.gridSortStore.trigger = true;
  }

  private _processTargetFilter(data: GridData[], filter: TargetFilterType) {
    data = filter.keywords?.length
      ? this.dataGridFilterToolsService.filterByKeywords(
          data,
          filter.keywords,
          [
            FilterRefEnum.TARGET_DESCRIPTION,
            FilterRefEnum.TARGET_META,
            FilterRefEnum.TARGET_TAGS
          ]
        )
      : data;

    data = filter.verticalSelection?.length
      ? this.dataGridFilterToolsService.filterByCharacterSeparatedInnerField(
          data,
          filter.verticalSelection,
          FilterRefEnum.TARGET_TAGS
        )
      : data;

    data = filter.themeSelection?.length
      ? this.dataGridFilterToolsService.filterByCharacterSeparatedInnerField(
          data,
          filter.themeSelection,
          FilterRefEnum.INDUSTRY_THEMES
        )
      : data;

    data = filter.geographySelection?.length
      ? this.dataGridFilterToolsService.filterByElements(
          data,
          filter.geographySelection,
          FilterRefEnum.TARGET_COUNTRY_CODE
        )
      : data;

    data =
      filter.ebitda?.min !== null || filter.ebitda?.max !== null
        ? this.dataGridFilterToolsService.filterByFinancialValueSet(
            data,
            {
              min: filter.ebitda?.min,
              max: filter.ebitda?.max
            },
            FilterRefEnum.TARGET_EBITDA
          )
        : data;

    data = filter.ebitda?.excludeNonReportedItems
      ? this.dataGridFilterToolsService.filterByExistingValue(
          data,
          FilterRefEnum.TARGET_EBITDA
        )
      : data;

    data =
      filter.revenue?.min !== null || filter.revenue?.max !== null
        ? this.dataGridFilterToolsService.filterByFinancialValueSet(
            data,
            {
              min: filter.revenue?.min,
              max: filter.revenue?.max
            },
            FilterRefEnum.TARGET_REVENUE
          )
        : data;

    data = filter.revenue?.excludeNonReportedItems
      ? this.dataGridFilterToolsService.filterByExistingValue(
          data,
          FilterRefEnum.TARGET_REVENUE
        )
      : data;

    if (this._getFilterCount() === filter.organizationSelection?.length) {
      data = filter.organizationSelection?.length
        ? this.dataGridFilterToolsService.filterByElements(
            data,
            filter.organizationSelection,
            FilterRefEnum.TARGET_ID
          )
        : data;
    } else {
      data = this.dataGridFilterToolsService.attachRows(
        this.dataSheetStorageService.gridData,
        data,
        filter.organizationSelection,
        FilterRefEnum.TARGET_ID
      );
    }

    return data;
  }

  private _processBuyerFilter(data: GridData[], filter: BuyerFilterType) {
    data = filter.keywords?.length
      ? this.dataGridFilterToolsService.filterByKeywords(
          data,
          filter.keywords,
          [FilterRefEnum.BUYER_DESCRIPTION, FilterRefEnum.BUYER_META]
        )
      : data;

    data = filter.geographySelection?.length
      ? this.dataGridFilterToolsService.filterByElements(
          data,
          filter.geographySelection,
          FilterRefEnum.BUYER_COUNTRY_CODE
        )
      : data;

    data = filter.sponsorSelection?.length
      ? this.dataGridFilterToolsService.filterByCharacterSeparatedInnerField(
          data,
          filter.sponsorSelection,
          FilterRefEnum.BUYER_SPONSOR_ID
        )
      : data;

    data = this.dataGridFilterToolsService.filterByElements(
      data,
      filter.buyerTypes,
      FilterRefEnum.BUYER_TYPE
    );

    if (this._getFilterCount() === filter.organizationSelection?.length) {
      data = filter.organizationSelection?.length
        ? this.dataGridFilterToolsService.filterByElements(
            data,
            filter.organizationSelection,
            FilterRefEnum.BUYER_ID
          )
        : data;
    } else {
      data = this.dataGridFilterToolsService.attachRows(
        this.dataSheetStorageService.gridData,
        data,
        filter.organizationSelection,
        FilterRefEnum.BUYER_ID
      );
    }

    return data;
  }

  private _processDealInfoFilter(data: GridData[], filter: DealInfoFilterType) {
    data =
      filter.date?.min !== null || filter.date?.max !== null
        ? this.dataGridFilterToolsService.filterByDateRange(
            data,
            filter.date.min!,
            filter.date.max!,
            FilterRefEnum.DEAL_DATE
          )
        : data;

    data =
      filter.enterpriseValue?.min !== null ||
      filter.enterpriseValue?.max !== null
        ? this.dataGridFilterToolsService.filterByFinancialValueSet(
            data,
            {
              min: filter.enterpriseValue?.min,
              max: filter.enterpriseValue?.max
            },
            FilterRefEnum.DEAL_EV
          )
        : data;

    data =
      filter.dealEnterpriseValueRevenue?.min !== null ||
      filter.dealEnterpriseValueRevenue?.max !== null
        ? this.dataGridFilterToolsService.filterByFinancialValueSet(
            data,
            {
              min: filter.dealEnterpriseValueRevenue?.min,
              max: filter.dealEnterpriseValueRevenue?.max
            },
            FilterRefEnum.DEAL_EV_REVENUE
          )
        : data;

    data =
      filter.dealEnterpriseValueEbitda?.min !== null ||
      filter.dealEnterpriseValueEbitda?.max !== null
        ? this.dataGridFilterToolsService.filterByFinancialValueSet(
            data,
            {
              min: filter.dealEnterpriseValueEbitda?.min,
              max: filter.dealEnterpriseValueEbitda?.max
            },
            FilterRefEnum.DEAL_EV_EBITDA
          )
        : data;

    data = filter.dealEnterpriseValueRevenue?.excludeNonReportedItems
      ? this.dataGridFilterToolsService.filterByExistingValue(
          data,
          FilterRefEnum.DEAL_EV_REVENUE
        )
      : data;

    data = filter.dealEnterpriseValueEbitda?.excludeNonReportedItems
      ? this.dataGridFilterToolsService.filterByExistingValue(
          data,
          FilterRefEnum.DEAL_EV_EBITDA
        )
      : data;

    data = filter.dealsTypes.length
      ? this.dataGridFilterToolsService.filterByElements(
          data,
          filter.dealsTypes,
          FilterRefEnum.DEAL_TYPE
        )
      : data;

    return data;
  }

  clearFilters() {
    this.dealDataFilterStore.gridFilter = DealDataFilterFactory.generate();
    this.dealRemoteFilterStore.remoteFilter = DealDataFilterFactory.generate();
    this.savedStoredGridStore.activeSavedStoredGrid = StoredPublicGridFilterFactory.generate();
    this.mutatorStore.gridMutation = MutationFactory.generate();
    this.dataSortingService.gridSortStore.sort = {
      direction: -1,
      column: this.dataSheetStorageService.fieldMap.get(FilterRefEnum.EV)!
    };

    this.dealDataFilterStore.filterCount = this._getFilterCount();
  }

  private _getFilterCount() {
    let count = 0;

    // Target Count

    if (this.dealDataFilterStore.gridFilter.targetFilter.keywords?.length)
      count =
        count +
        this.dealDataFilterStore.gridFilter.targetFilter.keywords?.length;

    if (
      this.dealDataFilterStore.gridFilter.targetFilter.verticalSelection?.length
    )
      count =
        count +
        this.dealDataFilterStore.gridFilter.targetFilter.verticalSelection
          ?.length;

    if (
      this.dealDataFilterStore.gridFilter.targetFilter.organizationSelection
        ?.length
    )
      count =
        count +
        this.dealDataFilterStore.gridFilter.targetFilter.organizationSelection
          ?.length;

    if (this.dealDataFilterStore.gridFilter.targetFilter.themeSelection?.length)
      count =
        count +
        this.dealDataFilterStore.gridFilter.targetFilter.themeSelection?.length;

    if (
      this.dealDataFilterStore.gridFilter.targetFilter.geographySelection
        ?.length
    )
      count =
        count +
        this.dealDataFilterStore.gridFilter.targetFilter.geographySelection
          ?.length;

    if (this.dealDataFilterStore.gridFilter.targetFilter.revenue?.min) ++count;

    if (this.dealDataFilterStore.gridFilter.targetFilter.revenue?.max) ++count;

    if (
      this.dealDataFilterStore.gridFilter.targetFilter.revenue
        ?.excludeNonReportedItems
    )
      ++count;

    if (this.dealDataFilterStore.gridFilter.targetFilter.ebitda?.min) ++count;

    if (this.dealDataFilterStore.gridFilter.targetFilter.ebitda?.max) ++count;

    if (
      this.dealDataFilterStore.gridFilter.targetFilter.ebitda
        ?.excludeNonReportedItems
    )
      ++count;

    // Buyer Count

    if (this.dealDataFilterStore.gridFilter.buyerFilter.keywords?.length)
      count =
        count +
        this.dealDataFilterStore.gridFilter.buyerFilter.keywords?.length;

    if (
      this.dealDataFilterStore.gridFilter.buyerFilter.organizationSelection
        ?.length
    )
      count =
        count +
        this.dealDataFilterStore.gridFilter.buyerFilter.organizationSelection
          ?.length;

    if (
      this.dealDataFilterStore.gridFilter.buyerFilter.geographySelection?.length
    )
      ++count;

    if (
      this.dealDataFilterStore.gridFilter.buyerFilter.sponsorSelection?.length
    )
      count =
        count +
        this.dealDataFilterStore.gridFilter.buyerFilter.sponsorSelection
          ?.length;

    if (this.dealDataFilterStore.gridFilter.buyerFilter.buyerTypes?.length < 4)
      count =
        count +
        4 -
        this.dealDataFilterStore.gridFilter.buyerFilter.buyerTypes?.length;

    // Info Count

    if (this.dealDataFilterStore.gridFilter.dealInfoFilter.date?.min) ++count;

    if (this.dealDataFilterStore.gridFilter.dealInfoFilter.date?.max) ++count;

    if (this.dealDataFilterStore.gridFilter.dealInfoFilter.enterpriseValue?.min)
      ++count;

    if (this.dealDataFilterStore.gridFilter.dealInfoFilter.enterpriseValue?.max)
      ++count;

    if (
      this.dealDataFilterStore.gridFilter.dealInfoFilter
        .dealEnterpriseValueRevenue?.min
    )
      ++count;

    if (
      this.dealDataFilterStore.gridFilter.dealInfoFilter
        .dealEnterpriseValueRevenue?.max
    )
      ++count;

    if (
      this.dealDataFilterStore.gridFilter.dealInfoFilter
        .dealEnterpriseValueRevenue?.excludeNonReportedItems
    )
      ++count;

    if (
      this.dealDataFilterStore.gridFilter.dealInfoFilter
        .dealEnterpriseValueEbitda?.min
    )
      ++count;

    if (
      this.dealDataFilterStore.gridFilter.dealInfoFilter
        .dealEnterpriseValueEbitda?.max
    )
      ++count;

    if (
      this.dealDataFilterStore.gridFilter.dealInfoFilter
        .dealEnterpriseValueEbitda?.excludeNonReportedItems
    )
      ++count;

    if (
      this.dealDataFilterStore.gridFilter.dealInfoFilter.dealsTypes?.length < 3
    )
      count =
        count +
        (3 -
          this.dealDataFilterStore.gridFilter.dealInfoFilter.dealsTypes
            ?.length);

    if (this.mutatorStore.gridMutation.statusOff.length > 0)
      count = count + this.mutatorStore.gridMutation.statusOff.length;

    return count;
  }
}
