import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import {
  ColumnField,
  GridHeader
} from '../../../data/type/grid/grid-header.type';
import { DataCellService } from '../../../service/data-cell.service';
import { GridData } from '../../../data/type/organization/grid-data.type';
import { ColumnFieldTypeEnum } from '../../../data/enum/grid/column-field-type.enum';
import { DataSheetStore } from '../../../service/store/data-sheet.store';
import { FilterRefEnum } from '../../../data/enum/grid/filter-ref.enum';
import { median } from 'mathjs';
import { NavigationStore } from '../../../../navigation/service/store/navigation.store';
import { DataType } from '../../../../navigation/data/emun/data-type';

@Component({
  selector: 'mul-summary-cell',
  templateUrl: './summary-cell.component.html'
})
export class SummaryCellComponent implements OnInit {
  @Input() header!: GridHeader;
  @Input() average!: { [p: string]: number | null };

  public datasource: GridData[] = [];
  fields: ColumnField[] = [];
  statusRef: string | null | undefined;

  constructor(
    private readonly dataCellService: DataCellService,
    private readonly dataSheetStorageService: DataSheetStore,
    private navigationStore: NavigationStore,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.statusRef = this.dataSheetStorageService.fieldMap.get(
      FilterRefEnum.STATUS
    );

    this.dataSheetStorageService.gridDisplayData$.subscribe(data => {
      this.datasource = data;
    });
  }

  ngOnInit(): void {
    this.fields =
      this.header?.fields.length > 0
        ? this.header?.fields
        : this.header?.subheaders.length > 0
        ? this.header.subheaders.flatMap(it => it.fields)
        : [];
  }

  transformFromRemote(field: ColumnField): string {
    if (
      ![
        ColumnFieldTypeEnum.NUMBER,
        ColumnFieldTypeEnum.DECIMAL,
        ColumnFieldTypeEnum.LONG_NUMBER,
        ColumnFieldTypeEnum.PERCENTAGE
      ].includes(<ColumnFieldTypeEnum>field.type)
    )
      return '';

    const mdn = this.average[field.ref];

    if (!mdn) return '';

    return field.type == ColumnFieldTypeEnum.YEAR
      ? Math.abs(mdn).toString()
      : this.dataCellService.formatCell(
          mdn,
          field.unit,
          field.type == ColumnFieldTypeEnum.DECIMAL ? 2 : 0
        );
  }

  transform(field: ColumnField): string {
    const arr: number[] = [];

    if (
      ![
        ColumnFieldTypeEnum.NUMBER,
        ColumnFieldTypeEnum.DECIMAL,
        ColumnFieldTypeEnum.LONG_NUMBER,
        ColumnFieldTypeEnum.PERCENTAGE
      ].includes(<ColumnFieldTypeEnum>field.type)
    )
      return '';

    this.datasource.forEach(it => {
      if (typeof it[field.ref] === 'number') {
        if (
          (this.statusRef && it[this.statusRef] === true) ||
          this.statusRef === undefined
        ) {
          arr.push(<number>it[field.ref]);
        }
      }
    });

    if (arr.length > 0) {
      const mdn = median(arr);
      return field.type == ColumnFieldTypeEnum.YEAR
        ? Math.abs(mdn).toString()
        : this.dataCellService.formatCell(
            mdn,
            field.unit,
            field.type == ColumnFieldTypeEnum.DECIMAL ? 2 : 0
          );
    }

    return '';
  }

  isRemote() {
    return this.navigationStore.navigationState?.dataType === DataType.DEALS;
  }
}
