<form [formGroup]="filterForm">
  <div class="filter">
    <mul-keyword-balloon
      (dataMutationEventEmitter)="setKeywords($event)"
      [selection]="keywordSelection"
      class="keyword"
      componentId="target_keywords"
      label="Target Keywords"
      placeholder="Type in a keyword and press ⮐"
    ></mul-keyword-balloon>
  </div>
  <div class="filter">
    <mul-multi-select
      (dataMutationEventEmitter)="setOrganizations($event)"
      [dataSet]="buyerOrganizationsDataSet"
      [selection]="buyerOrganizationSelection"
      componentId="target_name"
      label="Buyer Names"
      placeholder="Start typing a name"
      tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod."
    ></mul-multi-select>
  </div>
  <mul-multi-select
    (dataMutationEventEmitter)="setGeography($event)"
    [dataSet]="geographyDataSet"
    [selection]="geographySelection"
    class="filter"
    componentId="target_geography"
    label="Buyer Geography"
    placeholder="E.g. Europe, Brazil or India"
    tooltip="Lorem ipsum dolor sit amet, consectetur."
  ></mul-multi-select>
  <mul-multi-select
    (dataMutationEventEmitter)="setSponsor($event)"
    [dataSet]="sponsorDataSet"
    [selection]="sponsorSelection"
    class="filter"
    componentId="financial_sponsor"
    label="Financial Sponsor"
    placeholder="Start typing a name"
    tooltip="Lorem ipsum dolor sit amet, consectetur."
  ></mul-multi-select>
  <div class="filter">
    <div class="flex flex-col justify-center">
      <h2>Buyer Type</h2>
      <div class="mt-2 mb-2">
        <div class="flex items-center ml-1">
          <input
            (change)="setStrategicAcquireGroup()"
            class="w-3 h-3"
            formControlName="strategicAcquireGroup"
            id="strategic-acquire"
            type="checkbox"
          />
          <label class="ms-2 text-xs font-light" for="strategic-acquire"
            >Strategic acquirer</label
          >
        </div>
        <div class="switch-block flex flex-col ml-5">
          <div class="flex items-center ml-1">
            <input
              (change)="setPublicCorporation()"
              class="w-3 h-3"
              formControlName="publicCompanyType"
              id="public-company"
              type="checkbox"
              value="true"
            />
            <label class="ms-2 text-xs font-light mt-2" for="public-company"
              >Public company</label
            >
          </div>
          <div class="flex items-center ml-1">
            <input
              (change)="setPrivateCorporation()"
              class="w-3 h-3"
              formControlName="privateCompanyType"
              id="Private-company"
              type="checkbox"
            />
            <label class="ms-2 text-xs font-light mt-2" for="Private-company"
              >Private company</label
            >
          </div>
        </div>
      </div>
      <div class="mb-2">
        <div class="flex items-center ml-1">
          <input
            (change)="setFinancialBuyerGroup()"
            class="w-3 h-3"
            formControlName="financialBuyerGroup"
            id="financial-buyer"
            type="checkbox"
          />
          <label class="ms-2 text-xs font-light" for="financial-buyer"
            >Financial buyer</label
          >
        </div>
        <div class="switch-block flex flex-col ml-5">
          <div class="flex items-center ml-1">
            <input
              (change)="setPrivateEquity()"
              class="w-3 h-3"
              formControlName="privateEquityType"
              id="private-equity"
              type="checkbox"
            />
            <label class="ms-2 text-xs font-light mt-2" for="private-equity"
              >Private equity</label
            >
          </div>
          <div class="flex items-center ml-1">
            <input
              (change)="setBackedCorporation()"
              class="w-3 h-3"
              formControlName="peBackedPortCo"
              id="backend-corporation"
              type="checkbox"
            />
            <label
              class="ms-2 text-xs font-light mt-2"
              for="backend-corporation"
              >PE-backed-portco</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
