<h2>Financials</h2>
<form [formGroup]="filterForm">
  <div class="flex flex-col mt-3 mb-6">
    <h3>Enterprise Value ($M)</h3>
    <div class="flex">
      <div class="flex flex-col">
        <div class="relative float-label">
          <input
            (keyup.enter)="addItem()"
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="evMin"
            id="evMin"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            for="evMin"
            >min</label
          >
        </div>
      </div>
      <div class="flex flex-col ml-2">
        <div class="relative float-label">
          <input
            (keyup.enter)="addItem()"
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="evMax"
            id="evMax"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            for="evMax"
            >max</label
          >
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col mt-3 mb-6">
    <div class="flex flex-row">
      <h3 class="text-left w-full text-gray-600 mb-2">Revenue ($M)</h3>
      <div>
        <mul-select
          [options]="revenueMetricOptions"
          formGroupName="revenueMetric"
          (itemSelectedEvent)="addItem()"
        >
        </mul-select>
      </div>
    </div>

    <div class="flex flex-row">
      <div class="flex flex-col">
        <div class="relative float-label">
          <input
            (keyup.enter)="addItem()"
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="revenueMin"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >min</label
          >
        </div>
      </div>
      <div class="flex flex-col ml-2">
        <div class="relative float-label">
          <input
            (keyup.enter)="addItem()"
            class="block peer w-full bg-gray-100 border-0 border-gray-300 text-gray-900 text-xs font-light rounded focus:ring-0 focus: outline-none focus:ring-gray-300 p-2"
            formControlName="revenueMax"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >max</label
          >
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col mt-3 mb-6">
    <div class="flex flex-row">
      <h3 class="text-left w-full text-gray-600 mb-2">% revenue growth</h3>
      <div>
        <mul-select
          [options]="revenueGrowthMetricOptions"
          formGroupName="revenueGrowthMetric"
          (itemSelectedEvent)="addItem()"
        >
        </mul-select>
      </div>
    </div>

    <div class="flex flex-row">
      <div class="flex flex-col">
        <div class="relative float-label">
          <input
            (keyup.enter)="addItem()"
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="revenueGrowthMin"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >min</label
          >
        </div>
      </div>

      <div class="flex flex-col ml-2">
        <div class="relative float-label">
          <input
            (keyup.enter)="addItem()"
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="revenueGrowthMax"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >max</label
          >
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col mt-3 mb-6">
    <div class="flex flex-row">
      <h3 class="text-left w-full text-gray-600 mb-2">EBITDA ($M)</h3>
      <div>
        <mul-select
          [options]="ebitdaMetricOptions"
          formGroupName="ebitdaMetric"
          (itemSelectedEvent)="addItem()"
        >
        </mul-select>
      </div>
    </div>
    <div class="flex flex-row">
      <div class="flex flex-col">
        <div class="relative float-label">
          <input
            (keyup.enter)="addItem()"
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="ebitdaMin"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >min</label
          >
        </div>
      </div>
      <div class="flex flex-col ml-2">
        <div class="relative float-label">
          <input
            (keyup.enter)="addItem()"
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="ebitdaMax"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >max</label
          >
        </div>
      </div>
    </div>
  </div>

  <mul-switch
    (dataMutationEventEmitter)="setEbitdaProfitable($event)"
    [value]="filterForm.get('ebitdaProfitable')?.value ?? false"
    label="Must be EBITDA profitable"
  ></mul-switch>
</form>
