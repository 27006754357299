<div class="flex flex-col items-center justify-center  form">
  <h2>{{ label }}</h2>
  <input
    #keywordInputElement
    type="text"
    (keyup.enter)="onFormKeywordsItemChange()"
    formControlName="{{ componentId }}"
    id="{{ componentId }}"
    placeholder="{{ placeholder }}"
  />
</div>
<mul-balloon-selection
  [selection]="selectionObjectGroup"
  (dataMutationEventEmitter)="removeItem($event)"
></mul-balloon-selection>
