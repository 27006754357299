import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { GridData } from '../../../../data/type/organization/grid-data.type';
import { ColumnField } from '../../../../data/type/grid/grid-header.type';
import { DataCellService } from '../../../../service/data-cell.service';
import { ColumnFieldUnitEnum } from '../../../../data/enum/grid/column-field-unit.enum';

@Component({
  selector: 'mul-cell-number',
  templateUrl: './cell-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellNumberComponent implements OnInit {
  @Input() record!: GridData;
  @Input() field!: ColumnField;

  public recordElement = '';

  constructor(private columnFieldServiceService: DataCellService) {}

  ngOnInit(): void {
    this.recordElement = this.formatNumber(
      <number>this.record[this.field.ref],
      this.field.unit
    );
  }

  formatNumber(recordElement: number, unit: ColumnFieldUnitEnum): string {
    return this.columnFieldServiceService.formatCell(recordElement, unit);
  }
}
