import { BehaviorSubject } from 'rxjs';
import { GridSummary } from '../../data/type/grid/grid-summary.type';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GridSummaryStore {
  private _summary: BehaviorSubject<GridSummary> = new BehaviorSubject<
    GridSummary
  >({} as GridSummary);

  readonly summary$ = this._summary.asObservable();

  get summary(): GridSummary {
    return this._summary.getValue();
  }

  set summary(value: GridSummary) {
    this._summary.next(value);
  }
}
