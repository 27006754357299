import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DealRemoteFilter } from '../../data/type/filter/deal/deal-remote-filter.type';
import { Sort, SortFactory } from '../../data/type/grid/sort.type';
import { DealDataFilter } from '../../data/type/filter/deal/deal-data-filter.type';
import {
  TargetFilterFactory,
  TargetFilterType
} from '../../data/type/filter/deal/target-filter.type';
import {
  BuyerFilterFactory,
  BuyerFilterType
} from '../../data/type/filter/deal/buyer-filter-type';
import {
  DealInfoFilterFactory,
  DealInfoFilterType
} from '../../data/type/filter/deal/deal-info-filter.type';
import {
  Mutation,
  MutationFactory
} from '../../data/type/mutator/data-mutator.type';

@Injectable({
  providedIn: 'root'
})
export class DealRemoteFilterStore {
  private _dealRemoteFilter: BehaviorSubject<
    DealRemoteFilter
  > = new BehaviorSubject<DealRemoteFilter>(<DealRemoteFilter>{
    filter: {
      targetFilter: TargetFilterFactory.generate(),
      buyerFilter: BuyerFilterFactory.generate(),
      dealInfoFilter: DealInfoFilterFactory.generate()
    },
    mutation: MutationFactory.generate(),
    sorting: SortFactory.generate()
  });

  readonly dealRemoteFilter$ = this._dealRemoteFilter.asObservable();

  get dealRemoteFilter(): DealRemoteFilter {
    return this._dealRemoteFilter.getValue();
  }

  set dealRemoteFilter(value: BehaviorSubject<DealRemoteFilter>) {
    this._dealRemoteFilter = value;
  }

  set filter(value: DealDataFilter) {
    this._dealRemoteFilter.next({ ...this.dealRemoteFilter, filter: value });
  }

  set remoteSorting(sorting: Sort) {
    this._dealRemoteFilter.next({
      ...this.dealRemoteFilter,
      sorting
    });
  }

  set remoteFilter(filter: DealDataFilter) {
    this._dealRemoteFilter.next({
      ...this.dealRemoteFilter,
      filter
    });
  }

  set mutation(mutation: Mutation) {
    this._dealRemoteFilter.next({
      ...this.dealRemoteFilter,
      mutation: mutation
    });
  }

  set targetFilter(filter: TargetFilterType) {
    this._dealRemoteFilter.next({
      ...this.dealRemoteFilter,
      filter: {
        ...this.dealRemoteFilter.filter,
        targetFilter: filter
      }
    });
  }

  set buyerFilter(filter: BuyerFilterType) {
    this._dealRemoteFilter.next({
      ...this.dealRemoteFilter,
      filter: {
        ...this.dealRemoteFilter.filter,
        buyerFilter: filter
      }
    });
  }

  set dealFilter(filter: DealInfoFilterType) {
    this._dealRemoteFilter.next({
      ...this.dealRemoteFilter,
      filter: {
        ...this.dealRemoteFilter.filter,
        dealInfoFilter: filter
      }
    });
  }
}
