import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationService } from '../../../common/service/authorization.service';
import { AccessPromoService } from '../../../auth/services/access-promo.service';
import { UIOptionType } from '../../data/type/filter/UIOption.type';
import {
  TargetFilterFactory,
  TargetFilterType
} from '../../data/type/filter/deal/target-filter.type';
import {
  BuyerFilterFactory,
  BuyerFilterType
} from '../../data/type/filter/deal/buyer-filter-type';
import { Subscription } from 'rxjs';
import { DealDataGridFilterService } from '../../service/deal-data-grid-filter.service';
import {
  DealInfoFilterFactory,
  DealInfoFilterType
} from '../../data/type/filter/deal/deal-info-filter.type';
import { PromoEnum } from '../../../auth/data/enum/promo.enum';
import { DealRemoteFilterStore } from '../../service/store/deal-remote-filter.store';

@Component({
  selector: 'mul-deal-filter',
  templateUrl: './deal-filter.component.html'
})
export class DealFilterComponent implements OnInit, OnDestroy {
  private verticalSubscription: Subscription = new Subscription();
  private industryThemeSubscription: Subscription = new Subscription();
  private geographySubscription: Subscription = new Subscription();
  private targetOrganizationSubscription: Subscription = new Subscription();
  private buyerOrganizationSubscription: Subscription = new Subscription();
  private sponsorSubscription: Subscription = new Subscription();
  private filterSubscription: Subscription = new Subscription();

  activeFilterSection = 0;

  geographyDataSet: UIOptionType[] = [];

  verticalDataSet: UIOptionType[] = [];

  industryThemesDataSet: UIOptionType[] = [];

  targetOrganizationsDataSet: UIOptionType[] = [];

  buyerOrganizationsDataSet: UIOptionType[] = [];

  sponsorDataSet: UIOptionType[] = [];

  targetFilterSelection: TargetFilterType = {} as TargetFilterType;
  buyerFilterSelection: BuyerFilterType = {} as BuyerFilterType;
  dealInfoFilterSelection: DealInfoFilterType = {} as DealInfoFilterType;

  constructor(
    protected readonly authorizationService: AuthorizationService,
    private readonly dealDataGridFilterService: DealDataGridFilterService,
    private readonly accessPromoService: AccessPromoService,
    private readonly dealRemoteFilterStore: DealRemoteFilterStore
  ) {
    this.dealDataGridFilterService.dealDataFilterStore.filterCount$.subscribe(
      val => {
        if (val === 0) {
          this.targetFilterSelection = TargetFilterFactory.generate();
          this.buyerFilterSelection = BuyerFilterFactory.generate();
          this.dealInfoFilterSelection = DealInfoFilterFactory.generate();
        }
      }
    );

    this.filterSubscription = this.dealDataGridFilterService.dealDataFilterStore.gridFilter$.subscribe(
      val => {
        this.targetFilterSelection = val.targetFilter;
        this.buyerFilterSelection = val.buyerFilter;
        this.dealInfoFilterSelection = val.dealInfoFilter;
      }
    );
  }

  ngOnInit(): void {
    this.verticalSubscription = this.dealDataGridFilterService.filterStorageService.verticals$.subscribe(
      val => {
        this.verticalDataSet = val;
      }
    );
    this.industryThemeSubscription = this.dealDataGridFilterService.filterStorageService.industryThemes$.subscribe(
      val => {
        this.industryThemesDataSet = val;
      }
    );
    this.geographySubscription = this.dealDataGridFilterService.filterStorageService.geographies$.subscribe(
      val => {
        this.geographyDataSet = val;
      }
    );

    this.targetOrganizationSubscription = this.dealDataGridFilterService.filterStorageService.targetOrganizations$.subscribe(
      val => {
        this.targetOrganizationsDataSet = val;
      }
    );

    this.buyerOrganizationSubscription = this.dealDataGridFilterService.filterStorageService.buyerOrganizations$.subscribe(
      val => {
        this.buyerOrganizationsDataSet = val;
      }
    );

    this.sponsorSubscription = this.dealDataGridFilterService.filterStorageService.sponsorOrganizations$.subscribe(
      val => {
        this.sponsorDataSet = val;
      }
    );
  }

  ngOnDestroy(): void {
    this.verticalSubscription.unsubscribe();
    this.geographySubscription.unsubscribe();
    this.targetOrganizationSubscription.unsubscribe();
    this.buyerOrganizationSubscription.unsubscribe();
    this.filterSubscription.unsubscribe();
    this.sponsorSubscription.unsubscribe();
    this.industryThemeSubscription.unsubscribe();
  }

  setActiveFilterSection(section: number) {
    this.activeFilterSection = section;
  }

  setTargetFilter($event: TargetFilterType) {
    if (!this.authorizationService.isSubscribed()) {
      this.accessPromoService.show(PromoEnum.FILTER);
      return;
    }

    this.dealDataGridFilterService.dealDataFilterStore.targetFilter = $event;
    this.dealRemoteFilterStore.targetFilter = $event;
  }

  setBuyerFilter($event: BuyerFilterType) {
    if (!this.authorizationService.isSubscribed()) {
      this.accessPromoService.show(PromoEnum.FILTER);
      return;
    }

    this.dealDataGridFilterService.dealDataFilterStore.buyerFilter = $event;
    this.dealRemoteFilterStore.buyerFilter = $event;
  }

  setDealInfoFilter($event: DealInfoFilterType) {
    if (!this.authorizationService.isSubscribed()) {
      this.accessPromoService.show(PromoEnum.FILTER);
      return;
    }

    this.dealDataGridFilterService.dealDataFilterStore.dealInfoFilter = $event;
    this.dealRemoteFilterStore.dealFilter = $event;
  }
}
