import { Injectable, OnDestroy } from '@angular/core';
import { Sort } from '../data/type/grid/sort.type';
import { DataSheetStore } from './store/data-sheet.store';
import { GridSortStore } from './store/grid-sort.store';
import { GridData } from '../data/type/organization/grid-data.type';
import { FilterRefEnum } from '../data/enum/grid/filter-ref.enum';
import { NavigationStore } from '../../navigation/service/store/navigation.store';
import { Subscription } from 'rxjs';
import { DataType } from '../../navigation/data/emun/data-type';

@Injectable({
  providedIn: 'root'
})
export class DataSortingService implements OnDestroy {
  private sortingSubscription: Subscription = new Subscription();
  private triggerSubscription: Subscription = new Subscription();
  private gridDataSubscription: Subscription = new Subscription();

  constructor(
    public readonly gridSortStore: GridSortStore,
    private readonly dataSheetStorageService: DataSheetStore,
    private readonly navigationStore: NavigationStore
  ) {
    this.sortingSubscription = this.gridSortStore.sort$.subscribe(val => {
      if (this.navigationStore.navigationState?.dataType === DataType.PUBLIC) {
        const data = this.dataSheetStorageService.gridDisplayData;

        if (data.length > 0) {
          console.debug('SORTING: sort$', val, data.length);
          this._sortData(val, data);
        }
      }
    });

    this.triggerSubscription = this.gridSortStore.trigger$.subscribe(() => {
      if (this.navigationStore.navigationState?.dataType === DataType.PUBLIC) {
        const data = this.dataSheetStorageService.gridDisplayData;
        const sort = this.gridSortStore.sort;

        if (data.length > 0) {
          console.debug('SORTING: trigger$', sort, data.length);
          this._sortData(sort, data);
        }
      }
    });

    this.gridDataSubscription = this.dataSheetStorageService.gridData$.subscribe(
      val => {
        if (
          this.navigationStore.navigationState?.dataType === DataType.PUBLIC
        ) {
          const sortingRef = this.gridSortStore.sort;

          if (val.length > 0) {
            console.debug('SORTING: sort$', sortingRef, val.length);
            this._sortData(sortingRef, val);
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.sortingSubscription.unsubscribe();
    this.triggerSubscription.unsubscribe();
    this.gridDataSubscription.unsubscribe();
  }

  private _sortData(sortingRef: Sort, data: GridData[]) {
    const column = sortingRef.column
      ? sortingRef.column
      : this.dataSheetStorageService.fieldMap.get(FilterRefEnum.EV);

    const direction = sortingRef.direction ? sortingRef.direction : 1;

    data = data.sort((a, b) => {
      let aRef = a[<string>column];
      let bRef = b[<string>column];

      if (bRef == null) {
        return direction;
      }

      if (aRef == null) {
        return -1 * direction;
      }

      if (typeof bRef === 'string') {
        bRef = bRef.toString().toLowerCase();
      }

      if (typeof aRef === 'string') {
        aRef = aRef.toString().toLowerCase();
      }

      if (aRef > bRef) {
        return direction;
      }

      if (bRef > aRef) {
        return -1 * direction;
      }

      return 0;
    });

    this.dataSheetStorageService.gridDisplayData = data;
  }
}
