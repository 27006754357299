<form [formGroup]="filterForm">
  <div class="filter form">
    <h2>Date</h2>
    <div class="flex">
      <div class="flex flex-col">
        <div class="relative">
          <label
            class="{{
              filterForm.controls['dateMin'].touched &&
              filterForm.controls['dateMin'].hasError('pattern')
                ? 'error'
                : ''
            }}"
            for="dateMin"
            >from</label
          >
          <input
            #dateMin
            readonly
            class="focus:ring-0 focus:outline-none focus:ring-gray-300  {{
              filterForm.controls['dateMin'].touched &&
              filterForm.controls['dateMin'].hasError('pattern')
                ? 'error'
                : ''
            }}"
            formControlName="dateMin"
            id="dateMin"
            placeholder="dd/mm/yyyy"
            type="text"
          />
        </div>
      </div>
      <div class="flex flex-col ml-2">
        <div class="relative">
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 {{
              filterForm.controls['dateMax'].touched &&
              filterForm.controls['dateMax'].hasError('pattern')
                ? 'error'
                : ''
            }}"
            for="dateMax"
            >to</label
          >
          <input
            #dateMax
            readonly
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="dateMax"
            id="dateMax"
            placeholder="dd/mm/yyyy"
            type="text"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="filter form">
    <h2>Valuation ($M)</h2>
    <div class="flex">
      <div class="flex flex-col">
        <div class="relative float-label">
          <input
            (keyup.enter)="onFormItemChange()"
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="enterpriseValueMin"
            id="enterpriseValueMin"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            for="enterpriseValueMin"
            >min (⮐ to apply)</label
          >
        </div>
      </div>
      <div class="flex flex-col ml-2">
        <div class="relative float-label">
          <input
            (keyup.enter)="onFormItemChange()"
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="enterpriseValueMax"
            id="enterpriseValueMax"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            for="enterpriseValueMax"
            >max (⮐ to apply)</label
          >
        </div>
      </div>
    </div>
  </div>
  <div class="filter form">
    <h2>Multiples</h2>

    <div class="flex flex-col mt-3 mb-6">
      <h3>EV/Revenue</h3>
      <div class="flex">
        <div class="flex flex-col">
          <div class="relative float-label">
            <input
              (keyup.enter)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="evRevenueMin"
              id="evRevenueMin"
              placeholder=" "
              type="text"
            />
            <label
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              for="evRevenueMin"
              >min (⮐ to apply)</label
            >
          </div>
        </div>
        <div class="flex flex-col ml-2">
          <div class="relative float-label">
            <input
              (keyup.enter)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="evRevenueMax"
              id="evRevenueMax"
              placeholder=" "
              type="text"
            />
            <label
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              for="evRevenueMax"
              >max (⮐ to apply)</label
            >
          </div>
        </div>
      </div>
      <mul-switch
        (dataMutationEventEmitter)="setRevenueExcludeNonReportedItems($event)"
        [value]="
          filterSelection.dealEnterpriseValueRevenue.excludeNonReportedItems ||
          false
        "
        label="Exclude companies with no revenue"
      ></mul-switch>
    </div>
    <div class="flex flex-col mt-3 mb-6">
      <h3>EV/EBITDA</h3>
      <div class="flex">
        <div class="flex flex-col">
          <div class="relative float-label">
            <input
              (keyup.enter)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="evEbitdaMin"
              id="evEbitdaMin"
              placeholder=" "
              type="text"
            />
            <label
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              for="evEbitdaMin"
              >min (⮐ to apply)</label
            >
          </div>
        </div>
        <div class="flex flex-col ml-2">
          <div class="relative float-label">
            <input
              (keyup.enter)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="evEbitdaMax"
              id="evEbitdaMax"
              placeholder=" "
              type="text"
            />
            <label
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              for="evEbitdaMax"
              >max (⮐ to apply)</label
            >
          </div>
        </div>
      </div>
      <mul-switch
        (dataMutationEventEmitter)="setEbitdaExcludeNonReportedItems($event)"
        [value]="
          filterSelection.dealEnterpriseValueEbitda.excludeNonReportedItems ||
          false
        "
        label="Exclude companies with no EBITDA"
      ></mul-switch>
    </div>
  </div>
  <div class="filter">
    <div class="flex flex-col justify-center">
      <h2>Deal Type</h2>
      <div class="mt-2 mb-2">
        <div class="flex items-center ml-1">
          <input
            (change)="setStrategicMA()"
            class="w-3 h-3"
            formControlName="strategicMaType"
            id="strategic-ma"
            type="checkbox"
          />
          <label class="ms-2 text-xs font-light" for="strategic-ma"
            >Strategy M&A</label
          >
        </div>
      </div>
      <div class="flex items-center ml-1 font-light">
        <input
          (change)="setFinancialBuyOutGroup()"
          class="w-3 h-3"
          formControlName="financialBuyOutGroup"
          id="financial-buyout"
          type="checkbox"
        />
        <label class="ms-2 text-xs font-light" for="financial-buyout">
          Financial buyout
        </label>
      </div>
      <div class="switch-block flex flex-col ml-5 mt-2">
        <div class="flex items-center ml-1 font-light">
          <input
            (change)="setPeBuyOut()"
            class="w-3 h-3"
            formControlName="peBuyOutType"
            id="pe-buy-out"
            type="checkbox"
            value="true"
          />
          <label class="ms-2 text-xs" for="pe-buy-out">
            PE buyout
          </label>
        </div>
        <div class="flex items-center ml-1  mt-2 font-light">
          <input
            (change)="setPeAddOn()"
            class="w-3 h-3"
            formControlName="peAddOnType"
            id="pe-add-on"
            type="checkbox"
          />
          <label class="ms-2 text-xs" for="pe-add-on">
            PE add-on
          </label>
        </div>
      </div>
    </div>
  </div>
</form>
