import { Injectable, OnDestroy } from '@angular/core';
import { GridSummaryStore } from './store/grid-summary.store';
import { DealRemoteFilter } from '../data/type/filter/deal/deal-remote-filter.type';
import { ApiService } from '../../common/service/api.service';
import { ApiRoutes } from '../../common/data/enum/routes.enum';
import { catchError } from 'rxjs/operators';
import { AuthKeysEnum } from '../../auth/data/enum/auth-keys.enum';
import { Observable, Subscription, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from '../../common/service/storage.service';
import { GridDataResponse } from '../data/type/grid/grid-summary-response.type';
import { GridMutatorStore } from './mutator/grid-mutator.store';
import { NavigationStore } from '../../navigation/service/store/navigation.store';
import { DataType } from '../../navigation/data/emun/data-type';
import { DealRemoteFilterStore } from './store/deal-remote-filter.store';
import { DataPaginationStore } from './store/data-pagination.store';
import { DealDataGridFilterService } from './deal-data-grid-filter.service';

@Injectable({
  providedIn: 'root'
})
export class DataDealSummaryService implements OnDestroy {
  private gridMutatorSubscription: Subscription = new Subscription();

  constructor(
    private readonly storageService: StorageService,
    private readonly gridSummaryStore: GridSummaryStore,
    private readonly gridMutatorStorage: GridMutatorStore,
    private readonly dataPaginationStore: DataPaginationStore,
    private readonly dealRemoteFilterStore: DealRemoteFilterStore,
    private readonly dealDataGridFilterService: DealDataGridFilterService,
    private readonly navigationStore: NavigationStore,
    private readonly api: ApiService,
    private readonly router: Router
  ) {
    this.gridMutatorSubscription = this.gridMutatorStorage.gridMutation$.subscribe(
      () => {
        if (this.navigationStore.navigationState?.dataType === DataType.DEALS) {
          this.dataPaginationStore.page = 1;

          const activeGrid = this.dealDataGridFilterService.filterStorageService
            .activeGridView;

          let filter = this.dealRemoteFilterStore.dealRemoteFilter;
          filter = {
            ...filter,
            mutation: this.gridMutatorStorage.gridMutation
          };

          if (activeGrid) this.getSummary$(activeGrid, filter).subscribe();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.gridMutatorSubscription.unsubscribe();
  }

  getSummary$(
    viewId?: string | undefined,
    filter?: DealRemoteFilter
  ): Observable<GridDataResponse> {
    const url = ApiRoutes.DEAL_DATA_GRID_SUMMARY + (viewId ? `/${viewId}` : '');

    return this.api.httpPost$<GridDataResponse>(url, filter).pipe(
      catchError(err => {
        if (err.status === 401) {
          this.storageService.delete(AuthKeysEnum.USER_DATA_KEY);
          this.router.navigate([ApiRoutes.DATA_GRID_PUBLIC]).then(() => {});
        }

        return throwError(() => err);
      }),
      tap(val => {
        this.gridSummaryStore.summary = val.summary;
      })
    );
  }
}
