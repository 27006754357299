<div class="balloon-box flex flex-col">
  <div class="flex flex-wrap" *ngIf="selection.length > 0">
    <div
      *ngFor="let item of selection; let i = index"
      [hidden]="i >= displayCount && !displayAllSelected"
    >
      <span class="pill">
        {{ item.label }}
        <button type="button" (click)="removeItem(item.index)">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </span>
    </div>
  </div>

  <div class="flex justify-end">
    <span
      class="extra"
      (click)="displayAllSelected = !displayAllSelected"
      [hidden]="selection.length <= displayCount"
    >
      <div [hidden]="displayAllSelected">
        +{{ selection.length - displayCount }}
      </div>
      <div [hidden]="!displayAllSelected">
        -{{ selection.length - displayCount }}
      </div>
    </span>
  </div>
</div>
