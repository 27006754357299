import {
  Component,
  effect,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { AuthorizationService } from '../../../common/service/authorization.service';
import { DataPaginationService } from '../../service/data-pagination.service';
import { environment } from '../../../../environments/environment';
import { FILTER_WIDTH } from '../../data-sheet.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mul-data-sheet-summary',
  templateUrl: './data-sheet-summary.component.html'
})
export class DataSheetSummaryComponent implements OnDestroy {
  private pageLengthSubscription: Subscription = new Subscription();
  public env = environment;
  public recordCount = 0;
  public filterWidth = 0;

  @ViewChild('summary', { static: true }) summary: ElementRef<
    HTMLDivElement
  > = {} as ElementRef<HTMLDivElement>;

  constructor(
    public readonly authorizationService: AuthorizationService,
    private readonly dataPaginationService: DataPaginationService
  ) {
    this.pageLengthSubscription = this.dataPaginationService.dataPaginationStore.length$.subscribe(
      val => (this.recordCount = val)
    );

    effect(() => {
      this.filterWidth = FILTER_WIDTH();
      this._setToolBarWidth();
    });
  }

  ngOnDestroy(): void {
    this.pageLengthSubscription.unsubscribe();
  }

  @HostListener('window:resize')
  listener() {
    this._setToolBarWidth();
  }

  _setToolBarWidth() {
    this.summary.nativeElement.style.width =
      window.innerWidth - this.filterWidth - 5 + 'px';
  }
}
