import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  ColumnField,
  GridHeader
} from '../../../data/type/grid/grid-header.type';
import { GridData } from '../../../data/type/organization/grid-data.type';
import { ColumnFieldTypeEnum } from '../../../data/enum/grid/column-field-type.enum';

@Component({
  selector: 'mul-data-cell',
  templateUrl: './data-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCellComponent implements OnInit {
  @Input() header!: GridHeader;
  @Input() record!: GridData;

  fields: ColumnField[] = [];
  enumType: typeof ColumnFieldTypeEnum = ColumnFieldTypeEnum;

  ngOnInit(): void {
    this.fields =
      this.header?.fields.length > 0
        ? this.header?.fields
        : this.header?.subheaders.length > 0
        ? this.header.subheaders.flatMap(it => it.fields)
        : [];
  }

  isFieldType(
    fieldType: ColumnFieldTypeEnum,
    type: ColumnFieldTypeEnum
  ): boolean {
    return fieldType === type;
  }
}
