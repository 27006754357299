<div [hidden]="!isLoading" class="spinner-container">
  <ngx-spinner
    [fullScreen]="false"
    bdColor="{{ (darkMode$ | async) ? '#1f2937' : '#f9fafb' }}"
    color="{{ (darkMode$ | async) ? '#f9fafb' : '#1f2937' }}"
    name="data"
    size="medium"
    type="ball-clip-rotate"
  >
  </ngx-spinner>
</div>
