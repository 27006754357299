import { Injectable, OnDestroy } from '@angular/core';
import { FilterRefEnum } from '../../data/enum/grid/filter-ref.enum';
import { DataSheetStore } from '../store/data-sheet.store';
import { GridMutatorStore } from './grid-mutator.store';
import { Mutation } from '../../data/type/mutator/data-mutator.type';
import { GridData } from '../../data/type/organization/grid-data.type';
import { NavigationStore } from '../../../navigation/service/store/navigation.store';
import { Subscription } from 'rxjs';
import { DataType } from '../../../navigation/data/emun/data-type';
import { DataDealSummaryService } from '../data-deal-summary.service';

@Injectable({
  providedIn: 'root'
})
export class GridMutatorService implements OnDestroy {
  private dataSheetStorageServiceSubscription: Subscription = new Subscription();
  private gridMutatorStorageSubscription: Subscription = new Subscription();

  constructor(
    public readonly gridMutatorStorage: GridMutatorStore,
    private readonly dataSheetStorageService: DataSheetStore,
    private readonly dataDealSummaryService: DataDealSummaryService,
    private readonly navigationStore: NavigationStore
  ) {
    if (this.navigationStore.navigationState?.dataType === DataType.PUBLIC) {
      this.dataSheetStorageServiceSubscription = this.dataSheetStorageService.gridDisplayData$.subscribe(
        val => {
          if (val.length > 0) {
            const mutation = this.gridMutatorStorage.gridMutation;

            console.debug('MUTATING: gridMutation$', val.length, mutation);

            this._applyGridDataMutator(mutation, val);
          }
        }
      );

      this.gridMutatorStorageSubscription = this.gridMutatorStorage.gridMutation$.subscribe(
        val => {
          const data = this.dataSheetStorageService.gridDisplayData;

          if (data.length > 0) {
            console.debug('MUTATING: gridDisplayData$', data.length, val);

            this._applyGridDataMutator(val, data);
          }
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.gridMutatorStorageSubscription.unsubscribe();
    this.dataSheetStorageServiceSubscription.unsubscribe();
  }

  private _applyGridDataMutator(dataMutator: Mutation, data: GridData[]) {
    const skuRef = this.dataSheetStorageService.fieldMap.get(FilterRefEnum.SKU);
    const statusRef = this.dataSheetStorageService.fieldMap.get(
      FilterRefEnum.STATUS
    );

    if (statusRef && skuRef) {
      const statusOff = new Set<string>(dataMutator?.statusOff);

      data.forEach(it => (it[statusRef] = true));
      data = data.filter(it => statusOff.has(<string>it[skuRef]));
      data.forEach(it => (it[statusRef] = false));
    }
  }
}
