import { RouterModule, Routes } from '@angular/router';
import { DataSheetComponent } from './data-sheet.component';
import { NgModule } from '@angular/core';
import { dataSheetResolverService } from './service/resolvers/data-sheet-resolver.service';
import { geographyFilterResolver } from './service/resolvers/geography-filter-resolver.service';
import { gridViewResolverService } from './service/resolvers/grid-view-resolver.service';
import { verticalFilterResolver } from './service/resolvers/vertical-filter-resolver.service';
import { storedGridFilterInterceptor } from './service/interceptors/stored-public-grid-filter-interceptor.service';
import { sharedViewGuard } from '../common/service/guards/shared-view-guard.service';
import { navigationResolverService } from '../navigation/service/resolver/navigation-resolver.service';
import { authGuardService } from '../common/service/guards/auth-guard.service';
import { storedGridResolver } from './service/resolvers/stored-grid-resolver.service';
import { industryThemeFilterResolver } from './service/resolvers/industry-themes-filter-resolver.service';
import { targetOrganizationFilterResolver } from './service/resolvers/target-organization-filter-resolver.service';
import { buyerOrganizationFilterResolver } from './service/resolvers/buyer-organization-filter-resolver.service';
import { sponsorOrganizationFilterResolver } from './service/resolvers/sponsor-organization-filter-resolver.service';

const dataSheetRoutes: Routes = [
  {
    path: 'data-sheet',
    resolve: [
      navigationResolverService,
      gridViewResolverService,
      dataSheetResolverService,
      storedGridResolver,
      geographyFilterResolver,
      verticalFilterResolver,
      industryThemeFilterResolver,
      targetOrganizationFilterResolver,
      buyerOrganizationFilterResolver,
      sponsorOrganizationFilterResolver
    ],
    children: [
      {
        path: '',
        component: DataSheetComponent,
        canActivate: [authGuardService]
      },
      {
        path: 'shared/:storedGridFilterId',
        component: DataSheetComponent,
        resolve: [storedGridFilterInterceptor],
        canActivate: [sharedViewGuard]
      },
      {
        path: 'public/:storedGridFilterId',
        component: DataSheetComponent,
        resolve: [storedGridFilterInterceptor],
        canActivate: [sharedViewGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(dataSheetRoutes)],
  exports: [RouterModule]
})
export class DataSheetRoutingModule {}
