import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { GridData } from '../../../../data/type/organization/grid-data.type';
import { ColumnField } from '../../../../data/type/grid/grid-header.type';
import { ColumnFieldTypeEnum } from '../../../../data/enum/grid/column-field-type.enum';
import { DateTime } from 'luxon';

@Component({
  selector: 'mul-cell-date',
  templateUrl: './cell-date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellDateComponent implements OnInit {
  @Input() record!: GridData;
  @Input() field!: ColumnField;
  @Input() type!: ColumnFieldTypeEnum;

  public recordElement = '';

  ngOnInit(): void {
    this.recordElement = this.formatDate(
      <number>this.record[this.field.ref],
      this.type
    );
  }

  private formatDate(recordElement: number, type: ColumnFieldTypeEnum) {
    return type === ColumnFieldTypeEnum.SHORT_DATE
      ? DateTime.fromMillis(recordElement)
          .toFormat('dd-LLL-yy')
          .toString()
      : recordElement.toString();
  }
}
