import { Component } from '@angular/core';
import { AuthorizationService } from '../../../common/service/authorization.service';
import { NavigationStore } from '../../../navigation/service/store/navigation.store';
import { DataType } from '../../../navigation/data/emun/data-type';

@Component({
  selector: 'mul-data-sheet-actions',
  templateUrl: './data-sheet-actions.component.html'
})
export class DataSheetActionsComponent {
  constructor(
    private navigationStore: NavigationStore,
    protected readonly authorizationService: AuthorizationService
  ) {}

  isDealsData() {
    return this.navigationStore.navigationState?.dataType === DataType.DEALS;
  }
}
