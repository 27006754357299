export enum ApiRoutes {
  AUTHORIZATION_LOGIN = '/authorization/login',
  AUTHORIZATION_REFRESH_LOGIN = '/authorization/refresh-login',
  AUTHORIZATION_LOGIN_DOMAIN = '/authorization/domain',
  AUTHORIZATION_LOGIN_SUBSCRIPTION = '/authorization/subscription',
  AUTHORIZATION_SIGN_UP = '/registration',
  AUTHORIZATION_PASSWORD_REQUEST = '/password-reset/request',
  AUTHORIZATION_PASSWORD_RESET = '/password-reset/reset',
  AUTHORIZATION_ACCOUNT_ACTIVATION = '/account/activate',
  GEOGRAPHY = '/geography',
  INDUSTRY_THEME = '/industry-theme',
  VERTICAL = '/vertical',
  TARGET_ORGANIZATIONS = '/deal-organizations/target',
  BUYER_ORGANIZATIONS = '/deal-organizations/buyer',
  SPONSOR_ORGANIZATIONS = '/deal-organizations/sponsor',
  GRID = '/grid',
  DATA_GRID = '/public-data-grid',
  DATA_GRID_PUBLIC = '/data-grid-public',
  DATA_GRID_EXPORT = '/public-data-grid-export',
  DEAL_DATA_GRID = '/deal-data-grid',
  DEAL_DATA_GRID_SUMMARY = '/deal-data-grid/summary',
  DEAL_DATA_GRID_INDEX = '/deal-data-grid/index',
  DEAL_GRID_PUBLIC = '/deal-data-grid-public',
  DEAL_GRID_EXPORT = '/deal-data-grid-export',
  STORED_GRID = '/stored-grid',
  STORED_GRID_DEFINITION = '/stored-grid/definition',
  STORED_GRID_DATA = '/stored-grid-data',
  AUTHORIZATION_LOGOUT = '/authorization/logout',
  SUBSCRIPTION_CHECKOUT = '/subscription/checkout',
  SUBSCRIPTION = '/subscription',
  ACCOUNT_SUBSCRIPTION = '/account-subscription',
  CUSTOMER_SESSION = '/customer/session'
}

export enum AppRoutes {
  AUTHORIZATION_LOGIN = '/authorization/login',
  DATA_SHEET_PUBLIC = '/data-sheet-public/',
  DATA_SHEET = '/data-sheet/public/'
}
