import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataType } from '../../../navigation/data/emun/data-type';
import { NavigationStore } from '../../../navigation/service/store/navigation.store';
import { PublicDataService } from './public-data/public-data.service';
import { DealDataService } from './deal-data/deal-data.service';
import { SubscriptionStore } from '../store/subscription.store';
import { NavigationItemType } from '../../../navigation/data/type/navigation-item.type';

@Injectable({
  providedIn: 'root'
})
export class DataService implements OnDestroy {
  private navigationSubscription: Subscription = new Subscription();
  private subscriptionSubscription: Subscription = new Subscription();

  constructor(
    private navigationStore: NavigationStore,
    private publicDataService: PublicDataService,
    private subscriptionStore: SubscriptionStore,
    public dealDataService: DealDataService
  ) {
    this.subscribeToNavigationChanges();
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
    this.subscriptionSubscription.unsubscribe();
  }

  subscribeToNavigationChanges() {
    this.navigationSubscription = this.navigationStore.navigationState$.subscribe(
      val => {
        this._fetchData(val);
      }
    );
  }

  subscribeToSubscriptionChanges() {
    this.subscriptionSubscription = this.subscriptionStore.subscription$.subscribe(
      () => {
        this._fetchData(this.navigationStore.defaultNavigation);
      }
    );
  }

  private _fetchData(navigationType: NavigationItemType | null) {
    switch (navigationType?.dataType) {
      case DataType.PUBLIC:
        this.publicDataService.getAll$().subscribe();
        break;
      case DataType.DEALS:
        this.dealDataService.getAll$().subscribe();
        break;
      default:
        throw new Error(
          `Selected data ${navigationType?.dataType} type not supported`
        );
    }
  }
}
